import '../Stylesheet/css/styles.css';
import React, { Component, Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import AddAgenda from './AddAgenda';
import CreateAgenda from './CreateAgenda';
import UploadAgendaMediaContent from './UploadAgendaMediaContent';
import PlayAgendaContent from './PlayAgendaContent';
import RearrangeContent from './RearrangeContent';
import LatestInnovations from '../Discover/LatestInnovations';
import ReactPlayer from 'react-player';
import EventBus from 'eventing-bus';
import { format } from 'date-fns';
import fileDownload from 'js-file-download';
import { Heart, HeartFill, PlusSquareDotted, ArrowLeftRight, Pencil, PencilSquare, PlayBtn, Upload } from 'react-bootstrap-icons';
import CreateTag from './CreateTag'

import { showToastSuccessMessage, showToastErrorMessage, baseUrl, isLoading, apiKey } from '../../Constants/Constants';
import { AzureService } from "../../AzureService";
import { Buffer } from "buffer";
window.Buffer = window.Buffer || require("buffer").Buffer;

export default class PlayAgenda extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoadSpinner: true,
            showModal: false,
            mediaContents: [],
            editMediaContents: [],
            selectedMediaItem: null,
            selectedContent: null,
            isStopReceiveProps: false,
            showAddPopup: false,
            showcreatePopup: false,
            showFileUploadPopup: false,
            isPlayAgendaContent: false,
            isRearrangeContent: false,
            agendaId: this.props.selectedAgendaId,
            agendaData: null,
            tags: [],
            showAddTagPopup: false,
            selectedTag: null,
            isDisabled: '',
            isUploading : 'none'
        };
    }

    isShowAddTagPopup = (status) => {
        this.setState({ showAddTagPopup: status });
    }
    isShowTagPopup = (item = null, status) => {
        if (AzureService.IsValidLogin()) {
            this.setState({ showAddTagPopup: status, selectedTag: item });
        }
        else {
            AzureService.LoginIfRequre();
        }

    }

    isShowModal = (status) => {
        this.handleClose();
        this.setState({ showModal: status });
    }

    isShowcreatePopup = (status) => {
        if (AzureService.IsValidLogin()) {
            this.setState({ showcreatePopup: status });
        }
        else {
            AzureService.LoginIfRequre();
        }
    }

    isShowFileUploadPopup = (status) => {
        if (AzureService.IsValidLogin()) {
            this.setState({ showFileUploadPopup: status });
        }
        else {
            AzureService.LoginIfRequre();
        }
    }

    isShowAddPopup = (status) => {
        this.setState({ showAddPopup: status });
    }

    handleClose = () => {
        this.setState({ selectedMediaItem: null, isStopReceiveProps: false });
        this.props.onPopupClose(false);
    }

    componentDidMount() {
        EventBus.on("selectedMediaContent", this.getSelectedMedia);
        if (!this.state.selectedMediaItem) {
            if (this.props.selectedMediaItem != null && this.props.selectedMediaItem.mediaFiles != null
                && this.props.selectedMediaItem.mediaFiles.length > 0) {
                this.props.selectedMediaItem.mediaFiles[0].isSelectedAttachment = true;
            }
            this.setState({
                selectedContent: this.props.selectedMediaItem,
                mediaContents: this.props.selectedMediaItem != null ? [this.props.selectedMediaItem] : [],
                selectedMediaItem: this.props.selectedMediaItem != null && this.props.selectedMediaItem.mediaFiles != null && this.props.selectedMediaItem.mediaFiles.length > 0 ? this.props.selectedMediaItem.mediaFiles[0] : null
            });
        }
        this.getAgendaMediaFiles();
    }

    getAgendaMediaFiles() {
        if (this.state.agendaId && this.state.agendaId > 0) {
            fetch(baseUrl + '/GetSelectedAgenda?id=' + this.state.agendaId,
                {
                    headers: { 'XApiKey': apiKey }
                })
                .then(response => response.json())
                .then(records => {
                    if (records) {
                        this.setState({
                            agendaData: records, editMediaContents: records.mediaContent, mediaContents: records.mediaContent != null ? [this.getActiveMediaOnly(JSON.stringify(records.mediaContent))] : [],
                            tags: records.tags != null ? records.tags : [], selectedMediaItem: null, isStopReceiveProps: false
                        });
                    }
                    isLoading(false);
                    setTimeout(() => {
                        this.setState({ isLoadSpinner: false });
                    }, 1000);
                })
                .catch(error => { isLoading(false); console.log(error) })
        }
        else {
            isLoading(false);
            setTimeout(() => {
                this.setState({ isLoadSpinner: false });
            }, 1000);
        }
    }

    getActiveMediaOnly(mediaContent) {
        mediaContent = JSON.parse(mediaContent);
        if (mediaContent.mediaFiles != null && mediaContent.mediaFiles.length) {
            mediaContent.mediaFiles = mediaContent.mediaFiles.filter(item => item.isActive == 1);
        }
        return mediaContent;
    }

    getSelectedMedia = (item) => {
        if (this.state.selectedContent != null && this.state.selectedContent.mediaFiles != null && this.state.selectedContent.mediaFiles.length > 0) {
            this.state.selectedContent.mediaFiles.filter(f => (f.id == item.id) || (f.isSelectedAttachment == true)).map((sobj, sindex) => {
                sobj.isSelectedAttachment = false;
                if ((item.id > 0 && sobj.id === item.id) || (item.id == 0 && sobj.fileUrl === item.fileUrl)) {
                    sobj.isSelectedAttachment = true;
                }
            })
        }
        this.setState({ selectedContent: this.state.selectedContent, selectedMediaItem: item, isStopReceiveProps: true });
    };

    downloadMediaContent = (item) => {
        this.setState({ isDisabled: 'true' });
        this.setState({ isUploading: '' });
        fetch(baseUrl + '/Download?url=' + item.fileUrl,
            {
                headers: { 'XApiKey': apiKey }
            })
            .then(response => response.json())
            .then(buffer => {
                if (buffer) {
                    const nameSplit = item.fileUrl.split("/");
                    const duplicateName = nameSplit.pop();
                    fileDownload(Buffer.from(buffer, "base64"), duplicateName);
                    this.setState({ isDisabled: '' });
                    this.setState({ isUploading: 'none' });
                }
            })
            .catch(error => { console.log(error); this.setState({ isUploading: 'none' })})
    };

    changePinStatus() {
        fetch(baseUrl + '/PinAgenda', {
            method: 'POST',
            body: JSON.stringify({ agendaId: this.state.agendaData.agendaId, isPinned: (this.state.agendaData.isPinned > 0 ? 0 : 1), isActive: 1 }),
            headers: { 'Content-Type': 'application/json', 'XApiKey': apiKey }
        })
            .then(response => response.json())
            .then(records => {
                if (records) {
                    this.state.agendaData.isPinned = records.isPinned;
                    this.setState({ agendaData: this.state.agendaData });
                    showToastSuccessMessage("Updated Successfully");
                }
            })
            .catch(error => console.log(error))
    };

    UpdateAgenda = (agenda) => {
        this.setState({ agendaData: agenda });
        this.props.getUpdatedAgenda(agenda);
    }

    getCreatedNewTag = (tag) => {
        if (tag) {
            this.state.tags = this.state.tags == null ? [] : this.state.tags
            let isExist = false;
            this.state.tags.map((sobj, sindex) => {
                if (sobj.tagId === tag.tagID) {
                    sobj.tagName = tag.tagName;
                    isExist = true;
                    return;
                }
            })
            if (!isExist) {
                this.state.tags.push({ tagId: tag.tagID, tagName: tag.tagName });
            }
            this.setState({ tags: this.state.tags });
        }
    }

    updateDeletedTag = (tag) => {
        if (tag) {
            this.setState({ tags: this.state.tags.filter(item => item.tagId !== tag.tagId) });
        }
    }

    UpdateMediaContent = (data) => {
        if (data != null) {
            var media = data.mediaList;
            if (data.responseData != null) {
                data.responseData.agendaId = data.responseData.id;
                if (media.length > 0 && media[0].mediaFiles != null) {
                    data.responseData.mediaCount = media[0].mediaFiles.length;
                }
                data.responseData.agendaCreatedDate = data.responseData.createdDate;
                data.responseData.agendaModifiedDate = data.responseData.modifiedDate;
                this.UpdateAgenda(data.responseData)
            }

            if (this.state.editMediaContents != null && this.state.editMediaContents.mediaFiles) {
                this.state.editMediaContents.mediaFiles.map((sobj, index) => {
                    sobj.isActive = 0;
                    if (media.length > 0 && media[0].mediaFiles != null && media[0].mediaFiles.filter(item => item.id == sobj.id).length) {
                        sobj.isActive = 1;
                    }
                })
            }
            this.setState({ editMediaContents: this.state.editMediaContents, mediaContents: media });
            if (media != null) {
                media.map((obj, index) => {
                    if (obj.mediaFiles != null && obj.mediaFiles.length > 0) {
                        var existItem = obj.mediaFiles.filter(item => item.id === this.state.selectedMediaItem.id);
                        if (existItem == null || existItem.length == 0) {
                            this.setState({ selectedMediaItem: obj.mediaFiles[0] });
                        }
                    }
                })
            }
        }
    }

    playAgendaContent = (agendaId) => {
        window.location = `PlayAgenda?agendaId=${agendaId}`;
    }
    rearrangeContent = (agendaId) => {
        this.state.isRearrangeContent = true;
        window.location = `RearrangeContent?agendaId=${agendaId}`;
    }

    render() {
        let currentSelectedItem = this.state.selectedMediaItem;
        const isContentExist = (this.state.mediaContents != null && this.state.mediaContents.length > 0
            && this.state.mediaContents[0].mediaFiles != null && this.state.mediaContents[0].mediaFiles.length > 0);
        const enableButton = this.state.isLoadSpinner;
        return (
            <Fragment>

                <Modal show={this.props.showModalPopup || this.props.showAgendaPopup} onHide={this.handleClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <div>
                        <div>
                            <div>
                                <div className="modal-content">
                                    <div className="modal-body p-0 rounded">
                                        <div className="play-agenda-h" style={{ margin: "5px" }}>
                                            {(currentSelectedItem != null && ((currentSelectedItem.contentType == "File" || currentSelectedItem.contentType == "xlsxdocument") && ((currentSelectedItem.fileUrl.substr(currentSelectedItem.fileUrl.lastIndexOf('.') + 1)) == "xls" || (currentSelectedItem.fileUrl.substr(currentSelectedItem.fileUrl.lastIndexOf('.') + 1)) == "xlsx")) ?
                                                (
                                                    <iframe className={(currentSelectedItem.fileUrl.substr(currentSelectedItem.fileUrl.lastIndexOf('.') + 1))} width="100%" height="100%" frameborder="0" src={'https://view.officeapps.live.com/op/embed.aspx?src=' + currentSelectedItem.fileUrl}></iframe>

                                                )
                                                :
                                                currentSelectedItem != null && ((currentSelectedItem.contentType == "File" || currentSelectedItem.contentType == "pptxdocument") && ((currentSelectedItem.fileUrl.substr(currentSelectedItem.fileUrl.lastIndexOf('.') + 1)) == "ppt" || (currentSelectedItem.fileUrl.substr(currentSelectedItem.fileUrl.lastIndexOf('.') + 1)) == "pptx")) ?
                                                    (
                                                        <iframe className={(currentSelectedItem.fileUrl.substr(currentSelectedItem.fileUrl.lastIndexOf('.') + 1))} width="100%" height="100%" frameborder="0" src={'https://view.officeapps.live.com/op/embed.aspx?src=' + currentSelectedItem.fileUrl}></iframe>
                                                    )
                                                    :
                                                    currentSelectedItem != null && ((currentSelectedItem.contentType == "umbracoMediaArticle" || currentSelectedItem.contentType == "docxdocument") && ((currentSelectedItem.fileUrl.substr(currentSelectedItem.fileUrl.lastIndexOf('.') + 1)) == "doc" || (currentSelectedItem.fileUrl.substr(currentSelectedItem.fileUrl.lastIndexOf('.') + 1)) == "docx")) ?
                                                        (
                                                            <iframe className={(currentSelectedItem.fileUrl.substr(currentSelectedItem.fileUrl.lastIndexOf('.') + 1))} width="100%" height="100%" frameborder="0" src={'https://view.officeapps.live.com/op/embed.aspx?src=' + currentSelectedItem.fileUrl}></iframe>
                                                        )
                                                        :
                                                        currentSelectedItem != null && (currentSelectedItem.contentType == "umbracoMediaArticle" || currentSelectedItem.contentType == "pdfdocument" || currentSelectedItem.contentType == "File" && ((currentSelectedItem.fileUrl.substr(currentSelectedItem.fileUrl.lastIndexOf('.') + 1)) == "pdf")) ?
                                                            (
                                                                /*<object data={currentSelectedItem.fileUrl} type="application/pdf" width="100%" height="100%" style={{ backgroundColor: "white" }}>  </object>*/
                                                                <iframe className={(currentSelectedItem.fileUrl.substr(currentSelectedItem.fileUrl.lastIndexOf('.') + 1))} width="100%" height="100%" frameborder="0" src={`https://docs.google.com/gview?url=${currentSelectedItem.fileUrl}&embedded=true`} ></iframe>
                                                            ) :
                                                            currentSelectedItem != null && currentSelectedItem.contentType == "umbracoMediaArticle" ?
                                                                (
                                                                    <object data={currentSelectedItem.fileUrl} width="100%" height="100%" style={{ backgroundColor: "white" }}></object>
                                                                ) :
                                                                currentSelectedItem != null && (currentSelectedItem.contentType == "umbracoMediaVideo" || currentSelectedItem.contentType == "mediavideo") ?
                                                                    (
                                                                        <ReactPlayer controls={true} width='100%' height='100%' url={currentSelectedItem.fileUrl} className="video-content" />
                                                                    ) :
                                                                    currentSelectedItem != null && currentSelectedItem.contentType == "Url" ?
                                                                        (
                                                                            <iframe controls={true} width='100%' height='100%' src={currentSelectedItem.fileUrl} />
                                                                        ) :
                                                                        currentSelectedItem != null && (currentSelectedItem.contentType == "contentPage" || currentSelectedItem.contentType == "Image") ?
                                                                            (
                                                                                <img width="100%" height="100%" src={currentSelectedItem.fileUrl} />
                                                                            ) :
                                                                            currentSelectedItem != null ?
                                                                                (
                                                                                    <iframe controls={true} width='100%' height='100%' src={currentSelectedItem.fileUrl} />
                                                                                )
                                                                                : (
                                                                                    <img src="./images/video.png" alt="" width="100%" height="100%" />
                                                                                )
                                            )}
                                        </div>
                                        {isContentExist == true && (
                                            <div className="media-contents-h" style={{ paddingLeft: "2px", paddingRight: "2px", marginTop: "37px" }}>
                                                <LatestInnovations folderData={this.state.mediaContents} isHideHeader={true} agendaId={this.state.agendaId} isDraggable={this.props.openFrom === "AGENDA" ? true : false}
                                                    isPlaySelectedItem={true} selectedMediaContent={this.getSelectedMedia} comeFrom={this.props.openFrom === "AGENDA" ? "PLAYAGENDA" : "DISCOVER"} stopReceiveProps={this.state.isStopReceiveProps} />
                                            </div>
                                        )}
                                        <div className="px-4 py-4" className={this.props.openFrom === "DISCOVER" ? "agenda-content-min-h195" : "agenda-content-min-h255"}>
                                            <div className="row">
                                                {this.props != null && this.state.agendaData != null && this.props.openFrom !== "DISCOVER" && (
                                                    <div className="col-md-8" style={{ paddingLeft: "25px" }}>
                                                        <div className="fs-3 font-bold text-white lh-base">
                                                            {this.state.agendaData.agendaName} {this.state.agendaData.agendaModifiedDate != null ? (" - " + format(new Date(this.state.agendaData.agendaModifiedDate), 'dd/MM/yyyy')) : ""}{" "}
                                                            {this.state.agendaData.isPinned == 1 && (
                                                                <HeartFill style={{ marginLeft: "10px" }} fontSize="20" className="text-white pin-icon-p" onClick={() => this.changePinStatus()} />
                                                            )}
                                                            {this.state.agendaData.isPinned !== 1 && (
                                                                <Heart style={{ marginLeft: "10px" }} fontSize="20" className="text-white pin-icon-p" onClick={() => this.changePinStatus()} />
                                                            )}
                                                        </div>
                                                        <p className="fs-6 text-white lh-base my-2">
                                                            {this.state.agendaData.agendaDescription}
                                                        </p>
                                                        <div className="d-flex align-items-center view-video-details pt-3">
                                                            <span>Created: </span>
                                                            <span className="text-white ps-2">{this.state.agendaData.agendaCreatedDate != null ? format(new Date(this.state.agendaData.agendaCreatedDate), 'dd/MM/yyyy') : ""}</span>
                                                        </div>
                                                        <div className="d-flex align-items-center view-video-details my-3">
                                                            <span>Media count:</span>
                                                            <span className="text-white ps-2">{this.state.agendaData.mediaCount > 0 ? this.state.agendaData.mediaCount : ""}</span>
                                                        </div>
                                                    </div>
                                                )}
                                                {this.state.selectedContent != null && this.props.openFrom === "DISCOVER" && (
                                                    <div className="col-md-8" style={{ paddingLeft: "25px" }}>
                                                        <div className="fs-3 font-bold text-white lh-base">
                                                            {this.state.selectedContent.title}
                                                            <i className="bi bi-heart ms-4" />
                                                        </div>
                                                        <p className="fs-6 text-white lh-base my-2">
                                                            {this.state.selectedContent.description}
                                                        </p>
                                                        <div className="d-flex align-items-center view-video-details pt-3">
                                                            <span>Category: </span>
                                                            <span className="text-white ps-2">{this.state.selectedContent.categoryName}</span>
                                                        </div>
                                                        <div className="d-flex align-items-center view-video-details my-3">
                                                            <span>Owner:</span>
                                                            <span className="text-white ps-2">{this.state.selectedContent.creatorName}</span>
                                                        </div>
                                                            <div id="divLoading" style={{
                                                                display: this.state.isUploading, marginLeft: "300px", animation: "blinker 1.5s linear infinite",
                                                                color: "antiquewhite", fontFamily: "sans-serif", fontSize: "20px"
                                                            }}>Downloading file ...</div>
                                                    </div>
                                                )}
                                                <div className="col-md-4" style={{ marginRight: "0", marginLeft: "auto", marginTop: "8px" }}>
                                                    {this.props.openFrom === "DISCOVER" && (
                                                        <div style={{ paddingRight: "15px", paddingTop: "20px" }}>
                                                            <div className="d-flex justify-content-end">
                                                                <button type="button" className="btn btn-white" data-bs-toggle="modal"
                                                                    data-bs-target="#addToAgenda" onClick={() => this.isShowAddPopup(true)} >
                                                                    <img src="./images/icons/create.svg" alt="" />
                                                                    <span className="ps-3 pe-3">Add to Agenda</span>
                                                                </button>
                                                            </div>
                                                            <div className="d-flex justify-content-end mt-3">
                                                                <button type="button" disabled={this.state.isDisabled} className="btn btn-white" onClick={() => this.downloadMediaContent(currentSelectedItem)} >
                                                                    <img src="./images/icons/download.svg" alt="" />
                                                                    <span className="ps-2 pe-3">Download media</span>
                                                                </button>
                                                            </div>
                                                            <br></br>
                                                        </div>
                                                    )}

                                                    {this.props.openFrom === "AGENDA" && (
                                                        <div style={{ paddingRight: "15px", paddingTop: "35px" }}>
                                                            <div className="d-flex justify-content-end" onClick={() => isContentExist == true && this.playAgendaContent(this.state.agendaId)}>
                                                                <button disabled={!isContentExist} type="button" className="btn btn-white play-agenda-btn" data-bs-toggle="modal"
                                                                    data-bs-target="#addToAgenda">
                                                                    <PlayBtn fontSize="15" />
                                                                    <span className="ps-3 pe-3" style={{ fontSize: "10px", textAlign: "left" }}>Play Agenda</span>
                                                                </button>
                                                            </div>
                                                            <div className="d-flex justify-content-end mt-3" onClick={() => this.rearrangeContent(this.state.agendaId)}>
                                                                <button disabled={!isContentExist} type="button" className="btn btn-white play-agenda-btn" >
                                                                    <ArrowLeftRight fontSize="15" />
                                                                    <span className="ps-3 pe-3" style={{ fontSize: "10px", textAlign: "left" }}>Media Sorter</span>
                                                                </button>
                                                            </div>

                                                            <div className="d-flex justify-content-end mt-3" onClick={() => this.isShowcreatePopup(true)}>
                                                                <button disabled={enableButton} type="button" className="btn btn-white play-agenda-btn" >
                                                                    <PencilSquare fontSize="15" />
                                                                    <span className="ps-3 pe-3" style={{ fontSize: "10px", textAlign: "left" }}>Edit Agenda</span>
                                                                </button>
                                                            </div>

                                                            <div className="d-flex justify-content-end mt-3" onClick={() => this.isShowFileUploadPopup(true)}>
                                                                <button type="button" className="btn btn-white play-agenda-btn" >
                                                                    <Upload fontSize="15" />
                                                                    <span className="ps-3 pe-3" style={{ fontSize: "10px", textAlign: "left" }}>Media Upload</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            {this.props != null && this.state.agendaData != null && this.props.openFrom !== "DISCOVER" && (
                                                <div className="row" style={{ marginTop: "-30px" }}>
                                                    <div className="col-md-12" style={{ paddingLeft: "25px", width: "auto" }}>
                                                        <div className="d-flex view-video-details my-3">
                                                            <span className="pt-4">Tags:</span>
                                                            {this.state.tags != null && this.state.tags.length > 0 && (
                                                                <ul className="list-unstyled list-inline suggestion-section d-flex flex-wrap px-3 mb-2">
                                                                    {this.state.tags != null && this.state.tags.map((obj, index) => {
                                                                        return (
                                                                            <li key={index} className="mx-1 mt-1" style={{ paddingTop: "7px" }}>
                                                                                <a href="javascript:;" className="d-block active" style={{ padding: "6px", borderRadius: "10px" }}>
                                                                                    <PencilSquare fontSize="20" onClick={() => this.isShowTagPopup(obj, true)} />
                                                                                    <span className="ps-1 pe-1">{obj.tagName}</span>
                                                                                </a>
                                                                            </li>)
                                                                    })}
                                                                </ul>
                                                            )}
                                                            <div style={{ paddingTop: "10px", paddingLeft: "10px", cursor: "pointer" }}
                                                                href="#" class="fs-6 text-white lh-base my-2" onClick={() => this.isShowTagPopup(null, true)}>
                                                                <PlusSquareDotted fontSize="20" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal >
                {this.state && this.state.showAddTagPopup === true && (
                    <CreateTag showAddTagPopup={this.state.showAddTagPopup} getCreatedNewTag={this.getCreatedNewTag} getDeletedTag={this.updateDeletedTag} onClosePopup={this.isShowAddTagPopup} agendaId={this.state.agendaId} selectedTag={this.state.selectedTag} ></CreateTag>
                )}
                {this.state && this.state.showAddPopup === true && (
                    <AddAgenda showAddPopup={this.state.showAddPopup} onClosePopup={this.isShowAddPopup} categoryItem={this.state.selectedContent} ></AddAgenda>
                )}
                {this.state != null && this.state.showcreatePopup == true && (
                    <CreateAgenda showcreatePopup={this.state.showcreatePopup} onPopupClose={this.isShowcreatePopup} editData={this.state.agendaData}
                        mediaList={this.state.mediaContents} popUpSize={'lg'} getCreatedNewAgenda={this.UpdateAgenda} editMediaContent={this.state.editMediaContents}
                        getUpdateMediaContent={this.UpdateMediaContent} ></CreateAgenda>
                )}

                {this.state.isPlayAgendaContent === true && (
                    <PlayAgendaContent isPlayAgendaContent={this.state.isPlayAgendaContent} onPlayAgendaPopupClose={this.playAgendaContent}
                        selectedMediaItem={this.state.mediaContents} openFrom={'PLAYAGENDA'}></PlayAgendaContent>
                )}
                {this.state.isRearrangeContent === true && (
                    <RearrangeContent isPlayAgendaContent={this.state.isPlayAgendaContent} onPlayAgendaPopupClose={this.playAgendaContent}
                        selectedMediaItem={this.state.mediaContents} openFrom={'PLAYAGENDA'}></RearrangeContent>
                )}

                {this.state != null && this.state.showFileUploadPopup == true && (
                    <UploadAgendaMediaContent showFileUploadPopup={this.state.showFileUploadPopup} onPopupClose={this.isShowFileUploadPopup}
                        agendaData={this.state.agendaData} ></UploadAgendaMediaContent>
                )}
                {
                    <div id="divLoading" style={{
                        display: this.state.isUploading, marginRight: "800px", animation: "blinker 1.5s linear infinite",
                        color: "antiquewhite", fontFamily: "sans-serif", fontSize: "16px"
                    }}>Downloading file ...</div>
                }
            </Fragment >
        )
    }
}
